import { addons } from '@storybook/manager-api';
import { create } from '@storybook/theming/create';
import { environment } from '../src/environments';

addons.setConfig({
  theme: create({
    base: 'dark',
    brandTitle: `Fastuna UI v.${environment.version}`,
  }),
});
